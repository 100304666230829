import React from "react"

class Terms extends React.Component {
render() {
  return (
    <div style = {{maxWidth:`500px`, margin:`auto`}}>
    <h1>PRIVACY POLICY</h1>
    <p>
      <em
        >Silly Goose Receipts (“SGR,” "Company," "we," or “us”) respects your privacy and
        is committed to protecting it through our compliance with this “Privacy
        Policy.” Throughout this Privacy Policy, we refer to any person accessing
        or using these Websites (as defined below) as “you,” or the “User.”</em
      >
    </p>
    <p>
      <em
        >This Privacy Policy describes the types of information we may collect
        from you or that you may provide when you visit the website <a href="/">sillygoosereceipts.com</a> or any other services, features, content, offered
        by the Company (collectively our "Websites") and our practices for
        collecting, using, maintaining, protecting and disclosing that
        information.</em
      >
    </p>

    <strong>This Privacy Policy applies to information we collect:</strong>
    <ul>
      <li>On or through these Websites.</li>
      <li>
        In email, text, and other electronic messages between you and these
        Websites.
      </li>
      <li>
        When you interact with our advertising and applications on third-party
        websites and services, if those applications or advertising include links
        to this Privacy Policy.
      </li>
    </ul>

    <strong>It does not apply to information collected by:</strong>
    <ul>
      <li>Us offline or through any other means not listed above; or</li>
      <li>
        Any third party (including our affiliates and subsidiaries), including
        through any application or content that may link to or be accessWe require
        our employees to protect your Personal Information and keep it
        confidential. ible from, or on, the Websites. Please be aware that we have
        no control over the content and policies of those sites, and cannot accept
        responsibility or liability for their respective privacy practices.
      </li>
    </ul>

    <p>
      These Websites are offered and available to users who are 18 years of age or
      older, and reside in the United States or any of its territories or
      possessions. By using these Websites, you represent and warrant that you are
      of legal age to form a binding contract with us, and meet all of the
      foregoing eligibility requirements. If you do not meet all of these
      requirements, you must not access or use these Websites.
    </p>

    <p>
      Please read this Privacy Policy carefully to understand our policies and
      practices regarding your Personal Information (as defined below in
      INFORMATION WE COLLECT) and how we will treat it. If you do not agree with
      our policies and practices, your only choice is not to use our Websites. By
      accessing or using our Websites, you agree to this Privacy Policy. This
      Privacy Policy may change from time to time. Your continued use of the
      Websites after we make changes is deemed to be your acceptance of those
      changes, so please check the Privacy Policy periodically for updates.
    </p>

    <strong>The Company’s Privacy Assurance</strong>
    <ul>
      <li>
        We <strong>do not</strong> sell your Personal Information to any
        nonaffiliated third parties.
      </li>
      <li>
        We <strong>do not</strong> share your Personal Information with
        nonaffiliated third parties that would use it to contact you about their
        own products and services, unless you have allowed us to do so and as
        permitted pursuant to a joint marketing agreement.
      </li>
      <li>
        We require our employees to protect your Personal Information and keep it
        <strong>confidential</strong>.
      </li>
      <li>
        We require persons or organizations that represent or assist us in serving
        you to keep your information <strong>confidential.</strong>
      </li>
    </ul>

    <h4>CHILDREN UNDER THE AGE OF 13:</h4>
    <p>
      Our Websites are not intended for children under 13 years of age. No one
      under the age of 13 may provide any Personal Information to the Company or
      through the Websites. We do not knowingly collect Personal Information from
      children under 13. If you are under 13, do not use or provide any
      information through any of the Websites’ features/functionality, make any
      purchases through the Websites, use any of the interactive features that may
      be available on these Websites, or provide any information about yourself to
      us, including your name, address, telephone number, and/or email address
      and/or any screen name or username you may use. If we learn that we have
      collected or received Personal Information from a child under 13 without
      verification of parental consent, we will delete that information.
    </p>

    <h4>INFORMATION WE COLLECT:</h4>
    <p>
      We collect several types of information from and about Users of our
      Websites, including log data, device data, and Personal Information.
    </p>

    <strong><em>Log Data</em></strong>
    <p>
      When you visit our Websites, our servers may automatically log the standard
      data provided by your web browser. It may include your computer’s Internet
      Protocol (IP) address, your browser type and version, the pages you visit,
      the time and date of your visit, the time spent on each page, and other
      details.
    </p>

    <strong><em>Device Data</em></strong>
    <p>
      When you visit our Websites, we may collect additional data,
      that is about you but individually does not identify you, such as
      your annual revenue, gender, certain demographic information. This
      information is not considered Personal Information, as it is anonymized
      statistical data. sit our Websites, our servers may automatically log the
      standard data provided by your web browser. It may include your computer’s
      Internet Protocol (IP) address, your browser type and version, the pages you
      visit, the time and date of your visit, the time spent on each page, and
      other details.
    </p>

    <strong><em>Personal Information</em></strong>
    <p>
      We may collect information from you while you use these Websites, including:
    </p>

    <ul>
      <li>
        By which you may be personally identified, such as your first and last
        name, postal address, email address, and other identifying information
        that you choose to share Details of transactions you carry out through our
        Websites; and/or with us by which you may be contacted online or offline
        ("Personal Information"); and/or
      </li>
      <li>
        That is about you but individually does not identify you, such as your
        annual revenue, gender, certain demographic information. This information
        is not considered Personal Information, as it is anonymized statistical
        data.
      </li>
    </ul>

    <h4>HOW WE COLLECT INFORMATION:</h4>

    <strong><em>Information We Collect Automatically</em></strong>
    <p>
      As you navigate through and interact with our Websites, we may use automatic
      data collection technologies to collect certain information about your
      devices, browsing actions and patterns, as described above. The technologies
      we use for this automatic data collection may include cookies, flash
      cookies, or web beacons. For more information on these technologies, see our
      DATA COLLECTION POLICY below. The information we collect automatically is
      statistical data and does not include Personal Information, but we may
      maintain it for any of the reasons listed in HOW WE USE YOUR INFORMATION.
    </p>

    <strong><em>Information You Provide Directly to Us</em></strong>
    <p>The information we collect on or through our Websites may include:</p>
    <ul>
      <li>
        Information that you provide by filling in forms on our Websites. This
        includes information provided at the time of registering to use our
        Websites. We may also ask you for information when you report a problem
        with our Websites;
      </li>
      <li>
        Records and copies of your correspondence (including email addresses), if
        you contact us;
      </li>
      <li>Details of transactions you carry out through our Websites; and/or</li>
      <li>Your search queries on the Websites.</li>
    </ul>

    <strong><em>Information Provided to Us by Third Parties </em></strong>
    <p>
      If we receive Personal Information about you from a third party (for
      example, our business partners, data brokers, or credit reporting agencies),
      we will protect it as set out in this Privacy Policy. If you are a third
      party providing Personal Information about somebody else, you represent and
      warrant that you have such person’s consent to provide the Personal
      Information to us.
    </p>

    <h4>DATA COLLECTION POLICY:</h4>
    <p>
      The technologies we use for automatic data collection on these Websites may
      include:
    </p>
    <ul>
      <li>
        <strong>Cookies</strong> (or browser cookies). A cookie is a small file
        placed on the hard drive of your computer. You may refuse to accept
        browser cookies by activating the appropriate setting on your browser.
        However, if you select this setting you may be unable to access certain
        parts of our Websites. Unless you have adjusted your browser setting so
        that it will refuse cookies, our system will issue cookies when you direct
        your browser to our Websites;
      </li>
      <li>
        <strong>Flash Cookies</strong>. Certain features of our Websites may use
        local stored objects (or Flash cookies) to collect and store information
        about your preferences and navigation to, from and on our Websites. Flash
        cookies are not managed by the same browser settings as are used for
        browser cookies; and/or
      </li>
      <li>
        <strong>Web Beacons</strong>. Pages of the Websites, and our emails, may
        contain small electronic files known as web beacons (also referred to as
        clear gifs. pixel tags and single-pixel gifs) that permit the Company, for
        example, to count Users who have visited those pages, or opened an email,
        and for other related statistics (for example, recording the popularity of
        certain content on the Websites and verifying system and server
        integrity).
      </li>
    </ul>

    <h4>DO NOT TRACK:</h4>
    <p>
      “Do Not Track” is a preference To enable you to access and use our Websites;
      you can set your browser to let websites you visit know that you do not want
      them collecting certain information about you. We do not currently respond
      to, or honor, Do Not Track signals or requests from your browser.
    </p>

    <h4>THIRD-PARTY USE OF COOKIES AND OTHER TRACKING TECHNOLOGIES</h4>
    <p>
      Some content or applications on the Websites are served by third-parties,
      including servers, content providers and application providers. These third
      parties may use cookies (alone or in conjunction with web beacons or other
      tracking technologies) to collect information about you when you use our
      Websites. The information they collect may be associated with your Personal
      Information, or they may collect information, including Personal
      Information, about your online activities over time and across different
      websites and other online services. They may use this information to provide
      you with interest-based (behavioral) advertising or other targeted content.
    </p>
    <p>
      We do not control these third parties' tracking technologies or how they may
      be used. If you have any questions about an advertisement or other targeted
      content, you should contact the responsible provider directly.
    </p>

    <h4>HOW WE USE YOUR INFORMATION:</h4>
    <p>We may collect your information for a number of reasons, specifically:</p>
    <ul>
      <li>To enable you to access and use our Websites:</li>
      <li>
        To enable you to customize or personalize your experience of our Websites;
      </li>
      <li>To contact and communicate with you;</li>
      <li>To prevent fraud;</li>
      <li>For internal record keeping and administrative purposes;</li>
      <li>
        For analytics, market research, and business development, including to
        operate and improve our Websites;
      </li>
      <li>
        To offer additional benefits to you, including but not limited to sending
        promotional information about our products/services and/or facilitating
        competitions and/or giveaways;
      </li>
      <li>
        To provide you with information about third parties that may be of
        interest to you;
      </li>
      <li>
        To fulfill any other purpose for which your provide your information and
        consent; and/or
      </li>
      <li>
        To comply with our legal obligations and resolve any disputes that we may
        have.
      </li>
    </ul>

    <p>
      As stated above, we may use your information to contact you about our own
      and third-parties' services that may be of interest to you.
    </p>

    <h4>DISCLOSURE OF YOUR INFORMATION, GENERALLY:</h4>
    <p>
      We disclose Personal Information that we collect or that you provide, as
      described in this Privacy Policy, in the following circumstances:
    </p>
    <ul>
      <li>To our subsidiaries and affiliates;</li>
      <li>
        To contractors, service providers and other third parties we use to
        support our business and who are bound by contractual obligations to keep
        Personal Information confidential and use it only for the purposes for
        which we disclose it to them;
      </li>
      <li>
        To a buyer or other successor in the event of a merger, divestiture,
        restructuring, reorganization, dissolution or other sale or transfer of
        some or all of the Company’s assets, whether as a going concern or as part
        of bankruptcy, liquidation or similar proceeding, in which Personal
        Information held by the Company about our Websites’ Users is among the
        assets transferred. You acknowledge that such transfers may occur, and
        that any parties who acquire us may continue to use your Personal
        Information according to this Privacy Policy;
      </li>
      <li>To fulfill the purpose for which you provide it;</li>
      <li>
        For any other purpose disclosed by us when you provide the information; or
      </li>
      <li>With your consent.</li>
    </ul>

    <h4>DISCLOSURE OF YOUR INFORMATION TO THIRD PARTIES:</h4>
    <p>
      We may disclose your Personal Information to third party service providers
      for the purpose of enabling them to provide their services, if you have
      allowed us to do so, including but not limited to:
    </p>
    <ul>
      <li>Information Technology (“IT”) service providers;</li>
      <li>Data storage, hosting, and server providers;</li>
      <li>Analytics companies;</li>
      <li>Error loggers;</li>
      <li>Maintenance or problem-solving providers;</li>
      <li>Marketing or advertising providers;</li>
      <li>Professional advisors and payment systems operators; and/or</li>
      <li>
        Sponsors, promoters, or partners of any competition or promotion we run.
      </li>
    </ul>

    <p>
      We may also disclose your Personal Information to third parties for the
      purpose of establishing, exercising, or defending our legal rights,
      including but not limited to:
    </p>
    <ul>
      <li>Debt Collectors;</li>
      <li>Our legal counsel and/or prospective legal counsel; and/or</li>
      <li>
        Courts, tribunals, regulatory authorities, and law enforcement officers,
        as required by law.
      </li>
    </ul>
    <p>
      We may disclose aggregated, <em>anonymized</em>, statistical data and/or
      non-identifying information about our Users <em>without restriction</em>.
    </p>

    <h4>CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION:</h4>
    <p>
      We strive to provide you with choices regarding the Personal Information you
      provide to us. The following mechanisms should help to provide you with
      control over your information:
    </p>
    <ul>
      <li>
        <strong>Tracking Technologies and Advertising</strong>. You can set your
        browser to refuse all or some browser cookies, or to alert you when
        cookies are being sent. To learn how you can manage your Flash cookie
        settings, visit the Flash player settings page on Adobe's website. If you
        disable or refuse cookies, please note that some parts of these Websites
        may be inaccessible or not function properly.
      </li>
      <li>
        <strong>Promotional Offers from the Company/Affiliates</strong>. If you do
        not wish to have your email address/contact information used by the
        Company or its affiliates to promote our own or third parties' products or
        services, you can opt-out. If we have sent you a promotional email, you may use the unsubscribe option in the
        footer of the email. This opt out does not apply to information provided
        to the Company as a result of a product or service purchase, or other
        transactions.
      </li>
    </ul>

    <h4>NOTICE TO USERS FROM THE EUROPEAN ECONOMIC AREA:</h4>
    <p>
      If you are visiting and using the Services from the European Economic Area
      (“EEA”), please be aware that you are sending information (including
      personal data) to the United States where our partner platform’s servers are
      located. Our staff operating outside the EEA may process this information.
      The information may then be transferred within the United States or back out
      of the United States to other countries outside of your country of
      residence, depending on the type of information and how we store it. These
      countries (including the United States) may not necessarily have data
      protection laws as comprehensive or protective as those in your country of
      residence; however, our collection, storage and use of your personal data
      will at all times continue to be governed by this Privacy Policy.
    </p>

    <p>
      BY SUPPLYING YOUR PERSONAL INFORMATION TO US YOU EXPRESSLY AGREE TO THE
      TRANSFER OF YOUR PERSONAL INFORMATION OUT OF THE EEA, AND TO THE PROCESSING
      OF YOUR INFORMATION IN THE U.S., SUBJECT TO THIS PRIVACY POLICY
    </p>

    <h4>DATA SECURITY:</h4>
    <p>
      We have implemented measures designed to secure your Personal Information
      from accidental loss and from unauthorized access, use, alteration and
      disclosure. All information you provide to us through the Websites is
      secured by TLS technology, and is stored on our secure, password protected
      servers behind firewalls. We have implemented a restricted employee access
      for all of our servers containing personally identifiable information.
    </p>
    <p>
      Any payment transactions will be encrypted using SSL technology. We use
      Stripe and Shopify Payments (PCI-DSS compliant) as our payment processor. We
      fulfill and ship products through Printfection, which also utilizes SSL
      technology.
    </p>
    <p>
      The safety and security of your information also depends on you. Where we
      have given you (or where you have chosen) a password for access to certain
      parts of our Websites, you are responsible for keeping this password
      confidential. We ask you not to share your password with anyone.
    </p>

    <p>
      Unfortunately, the transmission of information via the Internet is not
      completely secure. Although we do our best to protect your Personal
      Information, we cannot guarantee the security of your Personal Information
      transmitted to our Websites. Any transmission of Personal Information is at
      your own risk. We are not responsible for circumvention of any privacy
      settings or security measures contained on the Websites.
    </p>

    <h4>CHANGES TO OUR PRIVACY POLICY</h4>
    <p>
      It is our policy to email registered users regarding changes we make to our
      Privacy Policy. If we make material changes to how we treat our Users'
      Personal Information, we will notify you by email to the most recent email
      address provided to the Company. The date the Privacy Policy was last
      revised is identified at the top of the page. You are responsible for
      ensuring we have an up-to-date active and deliverable email address for you,
      and for periodically visiting our Websites and this Privacy Policy to check
      for any changes.
    </p>

    <h4>AFFILIATE DISCLOSURE</h4>
    <p>
      Please note that some of the links on this site are affiliate links, and at
      no additional cost to you, we will earn a small commission if you decide
      to make a purchase after clicking through the link. When you use an
      affiliate link, you help support this website and the content we create. We
      sincerely appreciate your support!
    </p>
    <p>
      We are a participant in the Amazon Services LLC Associates Program, an
      affiliate advertising program designed to provide a means for sites to earn
      advertising fees by advertising and linking to Amazon.com.
    </p>

    <p>Last modified: December 31, 2019</p>
    </div>
)
}
}

export default Terms;
